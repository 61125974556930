// Titles
@mixin page-title {
    @include apply-map($f-title);
    @include font(60px, 68px);

    @include breakpoint(tablet) {
        @include font(50px, 54px);
    }
}

@mixin large-title {
    @include apply-map($f-title);
    @include font(50px, 54px);

    letter-spacing: 0.5px;

    @include breakpoint(tablet) {
        @include font(40px, 50px);
    }
}

@mixin large-title--alt {
    @include apply-map($f-title);
    @include font(50px, 54px);

    letter-spacing: 0.5px;

    @include breakpoint(tablet) {
        @include font(30px, 34px);
    }
}

@mixin medium-title {
    @include apply-map($f-title);
    @include font(40px, 50px);

    color: $f-title-color;

    @include breakpoint(tablet) {
        @include font(30px, 34px);
    }
}

@mixin medium-title--unresponsive {
    @include apply-map($f-title);
    @include font(40px, 45px);

    color: $f-title-color;
}

@mixin small-title {
    @include apply-map($f-title);
    @include font(30px, 34px);

    @include breakpoint(tablet) {
        @include font(18px, 28px);
    }
}

@mixin small-title--alt {
    @include apply-map($f-title);
    @include font(30px, 34px);

    @include breakpoint(tablet) {
        @include font(25px, 28px);
    }
}

@mixin small-title--alt-3 {
    @include apply-map($f-title);
    @include font(25px, 28px);

    @include breakpoint(tablet) {
        @include font(20px, 25px);
    }  
}

@mixin small-title--unresponsive {
    @include apply-map($f-title);
    @include font(30px, 34px);
}

@mixin tiny-title {
    @include apply-map($f-title);
    @include font(18px, 28px);
    @include mediumText;

    text-transform: capitalize;
    color: $f-title-color;
    letter-spacing: 1.25px;
}

@mixin tiny-title--alt {
    @include apply-map($f-primary);
    @include font(18px, 28px);

    color: $c-black;
    letter-spacing: 0.36px;
}

@mixin intro {
    @include font(18px, 28px);

    letter-spacing: 0.5px;

    @include breakpoint(tablet) {
        @include font(15px, 24px);
    }
}

@mixin intro--unresponsive {
    @include font(18px, 28px);

    letter-spacing: 0.5px;
}

@mixin intro--small {
    @include font(16px, 24px);
    letter-spacing: 0.32px;
}

@mixin subtitle {
    @include font(14px, 20px);

    text-transform: uppercase;
    color: $f-title-color;
    letter-spacing: 1.5px;
    font-weight: 500;
}

@mixin tiny-text--unresponsive {
    @include font (12px, 16px);
    @include mediumText;
}

@mixin form-label {
    @include font(15px, 24px);
}
